/**
 * Remove specified HTML elements while preserving their text content
 * @param {string} html - The HTML content to process
 * @param {string|string[]} elementsToStrip - Element tag name(s) to strip (e.g., 'a' or ['a', 'span'])
 * @returns {string} - The processed HTML content without the specified elements
 */
export const strip_elements_from_html = (html, elementsToStrip) => {
	elementsToStrip = Array.isArray(elementsToStrip) ? elementsToStrip : [elementsToStrip]; // Ensure elementsToStrip is an array

	const $temp = $("<div>").html(html); // Create a temporary div to hold and process the HTML content

	// Loop through each element to strip...
	elementsToStrip.forEach((el) => {
		// ... and find each element to strip within the temporary div ...
		$temp.find(el).each(function () {
			$(this).replaceWith($(this).text()); // ... and replace the element with its text content
		});
	});

	return $temp.html(); // Return the processed HTML content
};
